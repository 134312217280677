<template>
  <div class="vote-container">
    <div class="banner"></div>
    <div class="menu-wrap">
      <div class="menu-row">
        <div class="item" @click="heavyCargoClick">
          <img class="icon" src="../assets/img/home2.png" />
          <div class="column">
            <span class="title">寄快递</span>
            <span class="desc">一小时取件</span>
          </div>
        </div>         
        <div class="item" @click="heavyCargoClick">
          <img class="icon" src="../assets/img/home4.png" />
          <div class="column">
            <span class="title">扫码寄件</span>
            <span class="desc">快递员/运单二维码</span>
          </div>
        </div>            
      </div>
      <div class="menu-row" style="margin-top: 10px">
         <div class="item" @click="noticeClick(0)">
          <img class="icon" src="../assets/img/home6.png" />
          <div class="column">
            <span class="title">收货门店</span>
            <span class="desc">收货门店查询</span>
          </div>
        </div>
        <div class="item" @click="noticeClick(1)">
          <img class="icon" src="../assets/img/home1.png" />
          <div class="column">
            <span class="title">发货要求</span>
            <span class="desc">发货要求一览</span>
          </div>
        </div>
        <div class="item" @click="noticeClick(2)">
          <img class="icon" src="../assets/img/home3.png" />
          <div class="column">
            <span class="title">禁运商品</span>
            <span class="desc">禁运商品一览</span>
          </div>
        </div>
        <div class="item" @click="goAddress">
          <img class="icon" src="../assets/img/home5.png" />
          <div class="column">
            <span class="title">地址设置</span>
            <span class="desc">收货地址设置</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tips">
      根据快件清关要求,需要上传收件人正反面身份证照片~~~
    </div>
  </div>
</template>
 
<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      src: require('../assets/logo.jpg')
    }
  },
  methods:{
    noticeClick(val){
      this.$router.push({path:'/notice',query:{num:val}});
    },
    goAddress() {
      this.$router.push({path: '/addressList'})
    },
    heavyCargoClick(){
       Toast("开发中");
          // this.$router.push({path:'/expressList'});
    },
    subscribeClick(){
          this.$router.push({path:'/subscribe'});
    }
  }
}
</script> 

<style scoped>
.vote-container {
  position: relative; 
  width: 100%; 
}

.banner {
  width: 100%;
  height: 260px;
  background-size: cover;
  background-position: center;
  background-image: url('../assets/logo.jpg');
}

.menu-wrap {
  padding: 10px;
}

.menu-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  height: 70px;
  padding: 0 10px;
  box-sizing: border-box;
}

.item:nth-child(2n-1)::after {
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 20px;
  content: '';
  width: 1px;
  z-index: 999;
  background-color: #f5f5f5;
}

.item .icon {
  width: 50px;
  height: 50px;
  margin-right: 6px;
}

.item .title {
  color: #000;
  font-size: 14px;
}

.item .desc {
  color: #999;
  font-size: 11px;
  margin-top: 3px;
}

.tips{
  color: #999;
  font-size: 12px;
  text-align: center;
}

.column {
	display:flex;
	flex-direction: column;
}
</style>