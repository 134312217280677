import {get,post,get2} from './request' 
//获取门店信息
export function getShop() {
    return get('api/user/get_store_list')
}
//获取门店详情
export function getShopDetail() {
    return get('api/user/get_store_list')
}
//获取寄重货信息
export function getHeavyCargo() {
    return request.get('/message/unread', { params: params })
}
//获取余额明细
export function getBalanceDetails(val) {
    return get('/api/user/moneylog',{type:val})
}
//获取订单列表
export function getOrderList(params) {
    return post('/api/user/get_express_m_list',{params:params})
}
//获取订单详情
export function getOrderChildInfo(params,params1,params2) {
    return get('/api/user/get_express_list',{m_id:params,page:params1,type: params2})
}

//修改手机号
export function editTel(mobile) {
    return get('/api/user/changemobile2',{mobile:mobile})
}
//修改手机号
export function editStoreId(id) {
    return get('/api/user/change_store',{id:id})
}

//获取快递单号物品信息
export function getExpressInfo(params) {
    return get('/api/user/get_express_detail', { id: params })
}

//删除收件人信息
export function addressDel(params) {
    return post('/api/user/get_user_address_del', { id: params })
}

//获取发货要求
export function getDelivery() {
    return request.get('/message/unread', { params: params })
}
//获取禁运货品
export function getcontraband() {
    return request.get('/message/unread', { params: params })
}
//获取快递单号
export function getOrder(params) {
    return get('/api/user/get_express_m_list', { params: params })
}
//获取快递详情
export function getExpressDetails(params) {
    return get('/api/user/get_express_info', { id: params })
}


//获取母单详情
export function getOrderInfo(params) {
    return get('/api/user/get_express_m_info', { id: params })
}

//获取快递子单号
export function getOrderChild() {
    return request.get('/api/user/get_express_m_info', { params: params })
}
//获取包裹信息
export function getOrderGoods() {
    return request.get('/message/unread', { params: params })
}
//获取快递物理位置
export function getExpressLocal() {
    return request.get('/message/unread', { params: params })
}
//智能实别地址
export function getsmAddress(val) {
    return post('/api/user/address_recognition', { text: val })
}
//提交预约取件
export function saveSubscribe(params) {
    return post('/api/user/get_appointment_add', { params: params })
}
//取消预约取件
export function subscribeCancel(id) {
    return post('/api/user/appointment_cancel', { id: id })
}
//取消发货
export function expressCancel(id) {
    return post('/api/user/express_cancel_apply_add', { id: id })
}

//获取余额
export function balance() {
    return post('/api/user/get_now_money')
}

//充值
export function recharge(params) {
    return get('/addons/recharge/api.recharge/submit', params)
} 

//支付宝充值
export function rechargeAliPay(params) {
    return post(`/addons/recharge/api.recharge/submit?method=${params.method}&money=${params.money}&paytype=${params.paytype}`)
} 

//预约取消
export function getSubscribe() {
    return get('/api/user/get_appointment_list')
} 
//获取个人信息
export function getUserDetails() {
    return get('/api/user/index')
}
//获取个人信息
export function getUserDetailsById(index) {
    return get('/api/user/get_user_address_info',{id:index})
}
//获取个人信息
export function getUserInfo() {
    return get('/api/user/get_user_info')
}

//修改个人信息
export function updateUserDetails() {
    return get('/message/unread', { params: params })
}
//获取免责信息
export function getReliefInfo(value) {
    return get('/api/user/get_relief_info',{id:value})
}

//获取免责信息
export function getReliefList() {
    return get('/api/user/get_relief_list')
}

//同意免责
export function userRelief() {
    return post('/api/user/user_relief')
}

//地址列表
export function addressList() {
    return get('/api/user/get_user_address_list')
}
//文件上传
export function uploadFile(file) {
    return post('/api/common/upload',file)
}

//文件上传
export function uploadFileNew(file) {
    return post('/api/common/upload_cardsly',file)
}

//地址添加
export function addressInsert(params) {
    return post('/api/user/get_user_address_add', { params: params })
}
//地址修改
export function addressEdit(params) {
    return post('/api/user/get_user_address_edit', { params: params })
}
//母单支付
export function pay(params,payType) {
    return post('/api/user/pay_express_m', { id: params,use_user_money:payType})
}
//母单支付
export function pay2(params,payType) {
    return post('/api/user/pay_express_m', { id: params,use_user_money:0,paytype:payType})
}

//图片识别
export function wordRecognition(params) {
    return post('/api/user/word_recognition', { img_url:params})
}
//选择快递地址
export function expressAddressEdit(params) {
    return post('/api/user/express_address_edit', { params:params})
}

//获取顺丰快递物流
export function getSfRoutes(params) {
    return post('/api/user/get_sf_routes', { id:params})
}

/**
 * 身份证校验
 * **/
export function idCardCheck(params,type){
    return post("/api/user/baidu_id_card_ocr_api",{ image: params,idcardside:type})
}
/**
 * 身份证校验
 * **/
export function idCardCheck2(params){
    return post("/api/user/id_card_ocr_api",{ image:params})
}
//钱包信息
export function walletInfo() {
    return request.get('/message/unread', { params: params })
}
//钱包充值
export function walletRecharge() {
    return request.get('/message/unread', { params: params })
}
//获取openId
export function getOpenId(params) {
    return get('addons/third/api/callback', params)
}
//获取授权链接
export function getAuthUrl(params) {
   return get("addons/third/api/getAuthUrl",params)
}
//获取token
export function auth(params) {
   return post("addons/third/api/account",params)
}
export function postion(){
    return get("/api/user/get_position")
}
//发货要求
export function   consignment(val){
  return get("/api/user/get_express_require_list",{type:val});
}

//获取微信配置
export function   getSdkConfig(){
    return post("/api/user/wx_jssdk_sign");
}

//发货要求
export function   consignmentInfo(val){
  return get("/api/user/get_express_require_info",{id:val});
}



//咨询服务
export function   ask_kf(val){
    return get("/api/user/ask_kf");
}

//咨询服务
export function   sendMsg(val,val2){
    return post("/api/user/send_msg",{toid:val,content:val2});
}
//咨询服务
export function   getimlogList(val,val2){
    return get("/api/user/get_imlog_list",{toid:val,page:val2});
}
//咨询服务
export function   getimlogListTop(val,val2){
    return get("/api/user/get_imlog_list",{toid:val,top:1});
}

//身份证正面转Base64
export function getBase64(val){
    return post("/api/common/get_my_cardimg",{idcardimage:val});
}