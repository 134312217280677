<template>
  <div id="app"> 
    <div v-show="!(path==='/trajectory')&&!(path==='/chatIndex')&&!(path === '/address')&&!(path === '/addressList')&&!(path==='/expressDelivery')&&!(path === '/payTip')&&!(path=='/noticeDetails')&&!(path=='/orderDetails')&&!(path=='/walletPay')&&!(path=='/statement')" >     
      <van-tabbar v-model="active" active-color="#e01616" inactive-color="#999" >
        <van-tabbar-item icon="home-o" replace to="/" :placeholder="true">首页</van-tabbar-item>
        <van-tabbar-item icon="search" replace to="/orderList" :placeholder="true" >查快递</van-tabbar-item>
        <van-tabbar-item icon="todo-list-o" replace to="/subscribe" :placeholder="true">预约取件</van-tabbar-item>
        <!-- <van-tabbar-item icon="search" replace to="/expressDelivery" :placeholder="true">扫码</van-tabbar-item> -->
        <van-tabbar-item icon="friends-o" replace to="/user" :placeholder="true">我的</van-tabbar-item>
      </van-tabbar>
    </div> 
    
    <van-dialog v-model="show" title="紧急联系电话" @confirm="confirmMethod">
      <van-field  v-model="tel" name="电话号码" label="电话号码" placeholder="发件方电话号码" :rules="[{ required: true, message: '发件方电话号码' }]"/>
      <div style="color:#AAAAAA;text-aglin:center;font-size:12px;padding:10px">为防止紧急事情时不能及时联系到发件人，请填写可联系到的电话号码，电话格式09012349876，无需国号或- 感谢</div>
    </van-dialog>
    <router-view v-if="auth"/>
    <div style="height: 5rem;"></div>
    <van-popup v-model="mianZeshow" get-container="#app" class="overlay80vh">
      <h4 style="text-align:center;">免责申明</h4>
      <div v-html="mianzeData" style="font-size:11px"></div>
      <van-cell title="单元格" size="large">
          <template #title>
            <van-button type="info" @click="showTel">我同意上述条款</van-button> 
          </template>
           <template #default>
            <van-button type="warning" @click="cancelClick">取消</van-button> 
          </template>
      </van-cell> 
    </van-popup>
  </div>
</template>  
<style>
html,body,#app{
 		height: 100%;
 		width: 100%;
     background-color: rgb(241, 241, 241);
	}
.overlay80vh{
  width:58vh;
  text-align: left;
  text-indent:2em; 
  padding:5px;
}
</style>
<script> 
import { Toast,Dialog } from 'vant';
import {getOpenId,getAuthUrl,auth,getUserInfo,editTel,getReliefInfo,userRelief}from './tool/api'
export default {
  data(){
    return{
       path:'',
       mianzeData:'',
       mianZeshow:false,
       tabsShow:true,
       auth:true,
       active: 0,
       show:false,
       tel:'',
        
       code:'',
       state:'',
       isRouterAlive:true,
    }
  },
  provide(){
    return{
      reload:this.reload
    }
  },
  watch:{
    $route(to,from){ 
       this.path = to.path; 
        // getUserInfo().then(f=>{
        //   if(f.code===1){
        //       //未同意免责
        //       if(f.data.relief===0){
        //        if(to.path !== '/payTip'){
        //         getReliefInfo(1).then(g=>{
        //           this.mianzeData=g.data.content;
        //           this.mianZeshow=true;
        //         })
        //         // next();
        //        }else{
        //           this.auth=true;
        //        }
              
        //       }else{
        //          this.auth=true;
        //       }
        //     }else{
        //       this.auth=true;
        //     }
        //   })

         getUserInfo().then(f=>{
          if(f.code===1){
              if(f.data.mobile==null||f.data.mobile==""||f.data.mobile==undefined){
                this.show=true;
              }
               
            }else{
              this.auth=true;
            }
          })
    }
  },
  mounted(){  
      // this.getUserInfo(); 
  },
  created(){ 
    if(this.$route.path==='PayTip'){
       console.log("进来了未授权")
       this.auth=true;
       this.tabsShow=false;
    }else{
      console.log("进来了授权")
      // this.init()
    }
    
  },
  methods:{
    showTel(){
      this.show=true;
    },
    reload(){
      console.log('进来了 reload');
      this.isRouterAlive=false;
      this.$nextTick(function(){
        this.isRouterAlive=true;
      })
    },
    cancelClick(){
      Toast.fail("由于您不同意免责申明,将无法继续使用本系统!!")
      this.auth=false;
    },
    init(){ 
       this.code = this.getUrlCode().code;
       this.state= this.getUrlCode().state;
       if(this.code===undefined||this.code===null||this.code===''){
          getAuthUrl({url:window.location.href,platform:'wechat'}).then(a=>{ 
            console.log(a)
            if(a.code===1){
                window.location.href=a.data;
            }else{
              Toast.fail('获取code失败,请稍后重试');
            }
          })
       }else{
          getOpenId({platform:'wechat',state:this.state,code:this.code}).then(a=>{ 
             
             localStorage.setItem("openId",a.data);
             if(a.code===0){
                // Toast.fail('获取openId失败,请稍后重试');
             }else{
              //  sessionStorage.removeItem("token");
              //  sessionStorage.removeItem("tel");
              //  sessionStorage.removeItem("sptmid");
              //  sessionStorage.removeItem("avatar");
              //  sessionStorage.removeItem("nickname");
              //  sessionStorage.removeItem("userId");
                auth().then(c=>{                   
                this.auth=true;
                  if(c.code===1){ 
                   
                    sessionStorage.setItem("token",c.data.userinfo.token);           
                    sessionStorage.setItem("tel",c.data.userinfo.mobile);  
                    sessionStorage.setItem("sptmid",c.data.userinfo.sptmid);     
                  }else{
                     Toast.fail('授权失败,请稍后重试');
                  }
                })
             }
            //  console.log(a)
            //  if(a.data.third!==null&&a.data.third!==undefined){
              sessionStorage.setItem("avatar",a.data.third.avatar);
              sessionStorage.setItem("nickname",a.data.third.nickname);  
              sessionStorage.setItem("userId",a.data.user.id); 
            //  }
               
            
          })
       }
      
    },
    getCode () { // 非静默授权，第一次有弹框
       this.code = ''
       var local = window.location.href // 获取页面url
       var appid = 'wxf316d485798bb1a9' 
       this.code = this.getUrlCode().code // 截取code
       if (this.code == null || this.code === '') { // 如果没有code，则去请求
           window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
          //  this.code = this.getUrlCode().code // 截取code
       } else {
           getAuthUrl({platform:this.code})
       }
    },
    getUrlCode() { // 截取url中的code方法
       var url = location.search
       this.winUrl = url
       var theRequest = new Object()
       if (url.indexOf("?") != -1) {
           var str = url.substr(1)
           var strs = str.split("&")
           for(var i = 0; i < strs.length; i ++) {
               theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
           }
       }
       return theRequest
    }, 
    confirmMethod(){ 
      if(this.tel.length<=0||this.tel==''||this.tel==undefined||this.tel==null){
        Toast.fail("手机号不能为空,请输入发件方紧急联系电话尽量日本电话!!");
        this.show=true;
        return;
      }
      if(this.tel.length!=11){
        Toast.fail("手机号需要填写为11位的!!!");
        this.show=true;
        return;
      }
      editTel(this.tel).then(a=>{
        if(a.code===1){
          Toast.success("手机号码验证成功");
          //   userRelief().then(q=>{
          //     console.log(q) 
          //     this.mianZeshow=false;
          //     this.auth=true;
          //  })
        }else{
          Toast.fail(a.msg);
        }
           
      })
    }, 
  }
}
</script>

<style>
.van-nav-bar .van-icon {
    color: #999;
}
</style>

<style scoped> 
/deep/.van-tabbar-item__icon{
  position: relative;
    margin-bottom: 4px;
    font-size: 30px;
}
html{ font-size:13px;}
.van-tabbar {
  height: 75px;
}
</style>