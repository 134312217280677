import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
//import './tool/yunque'
import 'vant/lib/index.css';    
import { Dialog } from 'vant';
import clipboard from 'clipboard'
import {getUUid,formatDate,getUrlCode} from './tool/tool'
import {imgUrl} from './tool/url'
import smartParse from 'vue-smart-parse';
import {getOpenId,getAuthUrl,auth,getUserInfo,getReliefInfo,userRelief}from './tool/api'
Vue.use(smartParse)
Vue.prototype.$getUUid=getUUid;
Vue.prototype.$formatDate=formatDate;
Vue.prototype.$imgUrl=imgUrl;
Vue.config.productionTip = false
Vue.use(Vant); 
Vue.prototype.clipboard=clipboard;
router.beforeEach((to, from, next) => {  
  
  let code = getUrlCode(location.search).code;
  let state= getUrlCode(location.search).state;
  document.title = `${to.meta.title} | 名古屋胖哥速运物流系统`;
  const role = sessionStorage.getItem('token'); 
  console.log(role)

  if(role!=null&&role!=undefined&&role!=''){
      next();
  }
  if (!role && to.path !== '/payTip') { 
    if(code===undefined||code===null||code===''){
      let uuid=getUUid();
      sessionStorage.setItem("uuid",uuid);
      
       getAuthUrl({url:window.location.href,platform:'wechat'}).then(a=>{  
         console.log(a)
         if(a.code===1){
             window.location.href=a.data;
         }else{
           Toast.fail('获取code失败,请稍后重试');
         }
       })
    }else{
       getOpenId({platform:'wechat',state:state,code:code}).then(a=>{  
          sessionStorage.setItem("openId",a.data);
          if(a.code===0){
          }else{
             auth().then(c=>{   
               if(c.code===1){ 
                 sessionStorage.setItem("token",c.data.userinfo.token);           
                 sessionStorage.setItem("tel",c.data.userinfo.mobile);  
                 sessionStorage.setItem("sptmid",c.data.userinfo.sptmid);     
                 next();
               
               }else{
                  Toast.fail('授权失败,请稍后重试');
               }
             })
          }
           sessionStorage.setItem("avatar",a.data.third.avatar);
           sessionStorage.setItem("nickname",a.data.third.nickname);  
           sessionStorage.setItem("userId",a.data.user.id); 
         
            
         
       })
    }
  } else { 
      next();
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
