<template>
  <div class="home">
     <van-form @submit="onSubmit"> 
        <van-cell title="包裹编号" :value="scanValue" >
            <template #right-icon >
              <img src="../assets/san.png" width="20" height="20" @click="scanCode"/>
            </template>
        </van-cell> 
        <van-field  
          v-model="form.realWeight" 
          type="number"
          name="包裹实重"
          label="包裹实重"
          placeholder="包裹实重"
          :rules="[{ required: true, message: '请填写包裹实重' }]"
          @touchstart.native.stop="show = true"
        />
        <van-field
          v-model="form.remark" 
          name="备注"
          label="备注"
          placeholder="备注"
        /> 
        <van-cell title="包裹体重" is-link :value="form.packWeight" @click="packWeight" /> 
        <van-swipe-cell  v-for="(item,index) in formChild" :key="index"> 
            <van-field  :label="index+1" input-align="right" readonly/>
            <template #right>
              <van-button square type="danger" text="删除" @click="" /> 
            </template>
        </van-swipe-cell>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
     
      </van-form>
      <van-popup v-model="showPicker" round position="bottom">
       <van-picker show-toolbar :columns="packageWeightList" @cancel="showPicker = false" @confirm="onConfirm" />
      </van-popup>
      <!-- <van-number-keyboard :show="show" @blur="show = false"   v-model="form.realWeight"/> -->
  </div>
</template>
<script> 
import { Toast } from 'vant';
import {getSdkConfig} from '../tool/api'
import wx from 'weixin-js-sdk';
export default {
  data() {
    return {
      show: false,
      packageWeight:0,
      showPicker:false,
      packageWeightList:[
        '0.7Kg', 
        '1Kg', 
        '1.5Kg', 
        '2Kg', 
        '2.5Kg', 
        '3Kg', 
        '3.5Kg', 
        '4Kg', 
        '4.5Kg', 
        '5Kg', 
        '5.5Kg', 
        '6Kg', 
        '6.5Kg', 
        '7Kg', 
        '7.5Kg', 
        '8Kg',
        '8.5Kg', 
        '9Kg', 
        '9.5Kg', 
        '10Kg', 
      ],
      //扫码内容
      scanValue:'',
      form:{
          packageNo:'',
          guestInfo:'',
          realWeight:'0',
          packWeight:0,
          remark:''
      },
      productNum:10,
      formChild:[]
    };
  },
  created(){
    for(let n=0;n<this.productNum;n++){
        this.formChild.push({
          key:n+1,
          value:''
        })
    }
  },
  mounted(){
    getSdkConfig().then(a=>{
      wx.config({
        debug: false,
        appId: a.data.appId,
        timestamp: a.data.timestamp,
        nonceStr: a.data.nonceStr,
        signature: a.data.signature,
        jsApiList: ['checkJsApi', 'scanQRCode']
      });
    })
  },
  methods: {
    onConfirm(val){
        this.form.packWeight=val;
        this.showPicker=false;
    },
    scanCode(){
      wx.ready(function(){
        wx.checkJsApi({
						 jsApiList : ['scanQRCode'],
						 success : function(res) {
							if (res.checkResult.scanQRCode === true) {
								 wx.scanQRCode({ // 微信扫一扫接口  
							  })
							} else {
							   alert('抱歉，当前客户端版本不支持扫一扫')
							}
							
						 },
						 
						 fail: function (res) { // 检测getNetworkType该功能失败时处理
						   alert('fail' + res)
						 }
						
					}); 
      });
      wx.error(function(res) {
					alert("出错了：" + res.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
				});  
    },
    onSubmit(values) {
      console.log('submit', values);
    },  
    deleteMethod(){
     Toast("删除成功");
    },
    activated () {
    const self = this
    if (window.plus) {
      self.plusReady()
    } else {
      document.addEventListener('plusready', self.plusReady, false)
    }

    document.addEventListener('DOMContentLoaded', function () {
      // alert('DOMLoaded')
      self.domready = true
      self.plusReady()
    }, false)
    
    setTimeout(function () {
      self.sweep() // 扫码函数
    }, 1000)
    }, 
    packWeight(){
        this.showPicker=true;
    }
    
  }
};
</script>



