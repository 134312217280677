import Vue from 'vue'
import VueRouter from 'vue-router'
import ExpressDelivery from '../views/ExpressDelivery.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/expressDelivery',
    name: 'ExpressDelivery',
    component: ExpressDelivery,
    meta:{
      title:'扫码'
    }
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{
      title:'主页'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta:{
      title:'关于'
    }
  },
  {
    path: '/address',
    name: 'Address',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Address.vue'),
    meta:{
      title:'地址'
    }
  },
  {
    path: '/expressList',
    name: 'ExpressList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ExpressList.vue'),
    meta:{
      title:'箱单列表'
    }
  },
  {
    path: '/addressList',
    name: 'AddressList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddressList.vue'),
    meta:{
      title:'地址列表'
    }
  },
  {
    path: '/user',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue'),
    meta:{
      title:'个人主页'
    }
  },
  {
    path: '/userDetail',
    name: 'UserDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserDetails.vue'),
    meta:{
      title:'个人详情'
    }
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Subscribe.vue'),
    meta:{
      title:'预约'
    }
  },
  { 
    path: '/orderList',
    name: 'OrderList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderList.vue'),
    meta:{
      title:'订单列表'
    }
  },
  {
    path: '/wallet',
    name: 'Wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Wallet.vue'),
    meta:{
      title:'钱包'
    }
  },
  {
    path: '/subscribeList',
    name: 'SubscribeList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SubscribeList.vue'),
    meta:{
      title:'预约列表'
    }
  },
  {
    path: '/orderDetails',
    name: 'OrderDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderDetails.vue'),
    meta:{
      title:'订单详情列表'
    }
  },
  {
    path: '/trajectory',
    name: 'Trajectory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Trajectory.vue'),
    meta:{
      title:'物流轨迹'
    }
  },
  {
    path: '/heavyCargo',
    name: 'HeavyCargo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HeavyCargo.vue'),
    meta:{
      title:'重货信息'
    }
  } ,
  {
    path: '/notice',
    name: 'Notice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Notice.vue'),
    meta:{
      title:'消息'
    }
  } ,
  {
    path: '/shopDetails',
    name: 'ShopDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ShopDetails.vue'),
    meta:{
      title:'门店详情'
    }
  }  ,
  {
    path: '/chatIndex',
    name: 'ChatIndex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatIndex.vue')
  }    ,
  {
    path: '/pay',
    name: 'Pay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay.vue'),
    meta:{
      title:'商品支付'
    }
  }         ,
  {
    path: '/statement',
    name: 'Statement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Statement.vue'),
    meta:{
      title:'免责说明'
    }
  },
  {
    path: '/statementList',
    name: 'StatementList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StatementList.vue'),
    meta:{
      title:'免责说明'
    }
  },
  {
    path: '/orderProductDetails',
    name: 'OrderProductDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderProductDetails.vue'),
    meta:{
      title:'商品支付'
    }
  },
  {
    path: '/payTip',
    name: 'PayTip',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PayTip.vue'),
    meta:{
      title:'支付页面'
    }
  },
  {
    path: '/walletPay',
    name: 'WalletPay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WalletPay.vue'),
    meta:{
      title:'充值支付页面'
    }
  }   ,
  {
    path: '/noticeDetails',
    name: 'NoticeDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NoticeDetails.vue'),
    meta:{
      title:'消息详情'
    }
  }   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
}; 
export default router
